.stepper {
  &-wrapper {
    @apply w-full flex items-start;
    @screen lg {
      width: initial;
      align-items: center;
    }
  }
  &__list {
    @apply flex-1 flex items-center;
    @screen lg {
      flex: initial;
    }
    &:last-child {
      .stepper__line {
        @apply hidden;
      }
    }
    &:not(:last-child) {
      @apply mr-0;
      @screen lg {
        margin-right: 2rem;
      }
    }
    &.active {
      .stepper {
        &__count {
          @apply bg-primary-brand-dark text-white;
        }
        &__title {
          @apply text-primary-brand-dark;
        }
        &__line {
          border-bottom: 3px solid #1d5ab5;
        }
      }
    }
    &.done {
      .stepper {
        &__count {
          @apply bg-primary-brand-dark text-white;
          &__cont {
            @apply hidden;
          }
          &__check-icon {
            @apply block;
          }
        }
        &__line {
          border-bottom: 3px solid #1d5ab5;
        }
      }
    }
  }
  &__count {
    width: 30px;
    height: 30px;
    font-size: 15px;
    @apply flex-shrink-0 flex items-center justify-center font-bold bg-white rounded-full;
    box-shadow: 0px 4px 4px #d9d9d9;
    &__check-icon {
      @apply hidden;
    }
    @screen sm {
      width: 35px;
      height: 35px;
      font-size: 22px;
    }
  }
  &__title {
    @apply text-sm font-bold mt-1 text-center whitespace-nowrap;
    @screen md {
      font-size: 1.125rem;
    }
    @screen lg {
      margin-top: 0;
    }
  }
  &__line {
    width: 50px;
    height: 2px;
    border-bottom: 3px dashed #485d68;
    @screen sm {
      width: 100%;
    }
    @screen lg {
      width: 50px;
      margin-left: 2rem;
      margin-right: 0;
    }
  }
}
