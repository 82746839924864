.card-payment-method {
  @apply w-full mb-5 mt-5;
  height: 240px;
  border: 0;
  border-radius: 10px;
  background-image: linear-gradient(78.99deg, #1d5ab5 17.47%, #258ef1 89.89%);
  box-shadow: 0px 22px 22px rgba(171, 203, 232, 0.69);
  @screen sm {
    width: 430px;
    margin-bottom: 5rem;
    margin-top: 2.5rem;
  }
}
