.profile {
  &-container {
    background: rgba(255, 255, 255, 0.5);
    @apply rounded;
    padding: 20px;
    @screen md {
      padding: 30px 35px;
    }
  }
  &__header {
    @apply pb-5 border-b border-primary-brand-light;
  }
  &__body {
    @apply flex flex-col items-start;
    @screen lg {
      flex-direction: row;
    }
  }
  &-list {
    @apply flex flex-col;
    @screen md {
      flex-direction: row;
      align-items: center;
    }
    &__label {
      @apply w-full;
      @screen md {
        width: 25%;
      }
    }
    &__desc {
      @apply font-medium;
      @screen md {
        padding-left: 1.5rem;
      }
    }
    &:not(:last-child) {
      @apply mb-3;
    }
  }
}

.file-upload {
  &__input {
    @apply hidden;
  }
  &__btn {
    @apply block text-center border border-primary-brand-light bg-white rounded cursor-pointer;
    padding: 5px 14px;
    transition: all 300ms ease-in;
    &:hover {
      background-color: #eef3f9;
      box-shadow: 0px 4px 4px #e4ecf6;
    }
  }
}
