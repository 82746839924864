.progress-bar {
  &__fill {
    width: 0;
    height: 100%;
    @apply block bg-primary-brand;
    transition: all 300ms ease-in;
  }
  &-container {
    width: 120px;
    height: 16px;
    @apply bg-primary-brand-light;
    &.done {
      .progress-bar__fill {
        width: 75%;
      }
    }
  }
}