.connect-inbox-list {
  @screen lg {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  &__container {
    @apply w-full flex flex-col p-5 rounded-lg cursor-pointer;
    background: rgba(255, 255, 255, 0.8);
    // width: 246px;
    box-shadow: none;
    transition: box-shadow 300ms ease-in;
    &:hover {
      box-shadow: -5px 22px 149px rgba(147, 180, 210, 0.57);
    }
    @screen lg {
      height: 194px;
    }
  }
  &-wrapper {
    @apply flex items-center flex-wrap;
    @screen lg {
      margin-left: -0.5rem;
      margin-right: -0.5rem;
    }
  }
}
.vertical-border {
  @apply relative;
  @screen lg {
    &::after {
      content: '';
      width: 1px;
      height: 85%;
      @apply absolute bg-border-color;
      left: 0;
      top: 20px;
    }
  }
 
}

.inbox-connect {
  &__footer {
    @apply relative;
    width: calc(100% + 70px);
    left: -35px;
  }
}

.mailbox-score-graph {
  height: 90px;
  > canvas {
    height: 100% !important;
    width: auto !important;
  }
}

.pagination {
  &__wrapper {
    @apply flex gap-2 justify-center;
  }
  &__item {
    @apply px-2 cursor-pointer text-secondary;
    border-radius: 0.3rem;
    &:hover {
      background-color: #4b9eeb30;
    }
    &.selected {
      @apply text-white;
      background-color: #4b9eeb;
    }
  }
  &__button {
    @apply px-2 cursor-pointer;
    &__next {
      transform: rotate(180deg);
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
