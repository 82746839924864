.switch {
  &-container {
    width: 43px;
    height: 24px;
  }
  &-label {
    @apply inline-block relative w-full h-full cursor-pointer;
    background-color: #dcdada;
    // box-shadow: inset 0px 0px 8px #46ec89;
    border-radius: 40px;
    transition: all 300ms ease-in;
    &::after {
      content: "";
      @apply absolute rounded-full bg-white;
      top: 50%;
      transform: translateY(-50%);
      left: 3px;
      width: 17px;
      height: 17px;
      box-shadow: 0px 4px 7px #c3c2c0;
      transition: left 0.25s ease;
    }
  }
  &-input {
    @apply hidden;
    &:checked + .switch-label {
      @apply bg-green;
      &::after {
        left: 55%;
        box-shadow: 0px 4px 7px #26864d;
      }
    }
  }
}