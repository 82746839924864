.primary-header {
  padding: 8px 20px;
  height: 64px;
  @apply fixed w-full top-0 z-10 flex items-center bg-white;
  box-shadow: 0px 4px 4px #edeeef;
  // @media screen and (max-width: 992px) {
  //   min-width: 1280px;
  //   white-space: nowrap;
  // }
  @screen sm {
    padding: 8px 40px;
  }
}
.nav-list {
  @apply relative text-secondary text-base font-bold cursor-pointer py-3 px-6;
  transition: all 300ms ease-in;
  &:hover {
    @apply text-white bg-primary-brand;
  }
  @screen sm {
    line-height: 1.5rem;
    font-weight: 500;
  }
  &-wrapper {
    @apply flex flex-col absolute top-0 -right-[320px] pb-8 pt-16 w-[320px] h-screen bg-white overflow-hidden z-[10];
    box-shadow: 2px 0 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.4s ease-out, box-shadow 0.4s ease-out;
    @screen md {
      width: initial;
      height: initial;
      align-items: center;
      flex-direction: row;
      position: initial;
      background-color: transparent;
      padding: 0;
      overflow: initial;
      box-shadow: none;
    }
    @screen lg {
      @apply flex;
    }
  }
  @screen md {
    padding: 1.25rem 0;
    &:hover,
    &.active {
      @apply text-primary bg-transparent;
      &::after {
        @apply w-full opacity-100 visible;
      }
    }
    &.active {
      @apply font-bold;
    }
    &::after {
      content: '';
      @apply w-0 absolute bg-primary-brand opacity-0 invisible;
      bottom: 5px;
      left: -9px;
      height: 4px;
      border-radius: 4px 4px 0px 0px;
      transition: all 300ms ease-in;
      @screen sm {
        bottom: 0;
        left: 0;
      }
    }
    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .hamburger-menu {
    @apply absolute right-5 bg-none border-none flex flex-col justify-between cursor-pointer z-20;
    width: 28px;
    height: 20px;
    transition: all 0.3s ease;
    .bar {
      @apply w-full h-[2px] bg-[#333] rounded-[10px];
      transition: all 0.3s ease;
    }
  }
  .menu-toggle-checkbox:checked ~ .hamburger-menu .bar:nth-child(1) {
    transform: rotate(45deg);
    transform-origin: 5px;
  }
  .menu-toggle-checkbox:checked ~ .hamburger-menu .bar:nth-child(2) {
    opacity: 0;
  }
  .menu-toggle-checkbox:checked ~ .hamburger-menu .bar:nth-child(3) {
    transform: rotate(-45deg);
    transform-origin: 5px;
  }
}
.menu-toggle-checkbox:checked ~ .nav-list-wrapper {
  transform: translateX(-320px);
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2);
}
