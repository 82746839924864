// .loader {
//     @apply relative;
//     width: 50px;
//     height: 50px;
//     background: transparent;
//     border-top: 3px solid #595fed;
//     border-right: 3px solid transparent;
//     border-radius: 50%;
//     -webkit-animation: 1s spin linear infinite;
//     animation: 1s spin linear infinite;
//     z-index: 50;
//     &-container {
//         @apply fixed w-full h-full flex items-center justify-center top-0 right-0 left-0 bottom-0;
//         &--bg {
//             background-color: rgba(255, 255, 255, 0.8);
//             z-index: 40;
//         }
//     }
// }
// @keyframes spin {
//     from {
//         -webkit-transform: rotate(0deg);
//         transform: rotate(0deg);
//     }
//     to {
//         -webkit-transform: rotate(360deg);
//         transform: rotate(360deg);
//     }
// }

.loader {
  @apply flex items-center justify-center fixed top-0 right-0 left-0 bottom-0 m-auto;
  width: 80px;
  height: 80px;
  z-index: 90;
  &::after {
    content: '';
    @apply fixed w-full h-screen overflow-hidden top-0 left-0 right-0 bottom-0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: -1;
  }
  &-ring {
    @apply absolute;
    border: 6px solid blue;
    border-radius: 50%;
    animation: ripple 1.8s ease-out infinite;
    &:nth-child(2) {
      animation-delay: -0.6s;
    }
  }
}
@keyframes ripple {
  from {
    opacity: 1;
    width: 0;
    height: 0;
  }
  to {
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}

.loader-image {
  &__small {
    width: 1.5rem;
  }
  &__medium {
    width: 3rem;
  }
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
