.custom-checkbox {
  &__label {
    @apply flex items-center cursor-pointer;
    &__box {
      @apply relative inline-flex flex-shrink-0 w-6 h-6 bg-white border-[1.5px] border-border-color rounded transition-all;
      &::after {
        content: '';
        @apply absolute  w-[6px] h-[14px] top-0 left-[6px] opacity-0 invisible transition-all;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(45deg);
      }
    }
  }
  &__input {
    display: none;
    &:checked + label .custom-checkbox__label__box {
      @apply bg-primary-brand-dark;
      border: 1.5px solid transparent;
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &.md {
    .custom-checkbox {
      &__label {
        &__box {
          width: 20px;
          height: 20px;
          &::after {
            width: 4px;
            height: 10px;
            left: 6px;
            top: 2px;
          }
        }
      }
    }
  }
  &.sm {
    .custom-checkbox {
      &__label {
        &__box {
          width: 16px;
          height: 16px;
          &::after {
            width: 5px;
            height: 10px;
            left: 4px;
            top: 0.5px;
          }
        }
      }
    }
  }
}
