.dot-indicator {
  width: 12px;
  height: 12px;
  @apply inline-block rounded-full bg-green;
  &--success {
    @apply bg-green;
  }
  &--warning {
    @apply bg-yellow;
  }
  &--error {
    @apply bg-red;
  }
}