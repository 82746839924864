.dropdown-menu {
  @apply w-full absolute left-0 bg-white opacity-0 invisible rounded z-10;
  box-shadow: 0px 0px 4px #bec6d1, -10px 14px 17px rgba(147, 180, 210, 0.37);
  transform: scale(0.7);
  transition: all 0.3s;
  &--type-2 {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25), 0px 20px 20px rgba(147, 180, 210, 0.73);
  }
  &.open {
    transform: scale(1);
    @apply opacity-100 visible;
  }
  &__list {
    @apply flex items-center font-medium text-secondary rounded-md cursor-pointer;
    padding: 8px 12px;
    transition: all 300ms ease-in;
    &:not(:last-child) {
      margin-bottom: 2px;
    }
    &.rounded {
      border-radius: 4px;
    }
    &.rounded-2 {
      border-radius: 2px;
    }
    &.rounded-none {
      border-radius: 0;
    }
    &:hover {
      @apply text-primary-brand-dark;
      background-color: #dce8f3;
      transition: all 300ms ease-in;
    }
    &__bullet-icon {
      width: 18px;
      height: 16px;
      border-radius: 16px;
      margin-right: 10px;
      background-color: #4b9dea;
      box-shadow: 0px 4px 4px rgba(75, 157, 234, 0.44);
      &--color2 {
        background-color: #1d5ab5 !important;
      }
      &--color3 {
        background-color: #f48b8b !important;
      }
      &--color5 {
        background-color: #6cbb8c !important;
      }
      &--color4 {
        background-color: #bdcad6 !important;
      }
      &--color6 {
        background: #fff url(../../../icons/icon-send.svg) no-repeat;
      }
    }
  }
  &--sm {
    padding: 8px;
    .dropdown-menu__list {
      padding: 6px 14px;
    }
  }
  &--xs {
    .dropdown-menu__list {
      padding: 6px 14px;
    }
  }
  &.no-hover {
    .dropdown-menu__list {
      &:hover {
        @apply bg-white text-primary;
      }
    }
  }
}
