.avatar {
  &-container {
    @apply flex items-center justify-center flex-shrink-0 rounded-full bg-white;
    > img {
      @apply w-full h-full rounded-full;
    }
    &--cover {
      > img {
        @apply object-cover;
      }
      background-color: #D9D9D9;
    }
    &--contain {
      > img {
        @apply object-contain;
      }
    }
    &--border {
      @apply border border-border-color;
    }
  }
  &-group &{
    &-container {
      @apply relative;
      &:nth-child(2) {
        left: -10px;
      }
      &:nth-child(3) {
        left: -20px;
      }
      &:nth-child(4) {
        left: -30px;
      }
    }
  }
}
