.custom-tooltip {
  position: relative;
  display: inline-block;
  // &__arrow {
  //   .custom-tooltip .tooltiptext::after {
  //     content: '';
  //     position: absolute;
  //     border-width: 5px;
  //     border-style: solid;
  //   }
  //   .custom-tooltip .tooltiptext__top::after {
  //     top: 100%;
  //     left: 50%;
  //     margin-left: -5px;
  //     border-color: #6c7b8b transparent transparent transparent;
  //   }

  //   .custom-tooltip .tooltiptext__left::after {
  //     top: 50%;
  //     left: 100%;
  //     margin-top: -5px;
  //     border-color: transparent transparent transparent #6c7b8b;
  //   }

  //   .custom-tooltip .tooltiptext__bottom::after {
  //     bottom: 100%;
  //     left: 50%;
  //     margin-left: -5px;
  //     border-color: transparent transparent #6c7b8b transparent;
  //   }
  //   .custom-tooltip .tooltiptext__right::after {
  //     top: 50%;
  //     right: 100%;
  //     margin-top: -5px;
  //     border-color: transparent #6c7b8b transparent transparent;
  //   }
  // }
}

.custom-tooltip .tooltiptext,
.tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 200px;
  background-color: #6c7b8b;
  color: #fff;
  border-radius: 6px;
  z-index: 1;
  padding: 8px;

  &__email {
    left: 40%;
    width: fit-content;
  }
  &__left {
    top: -5px;
    bottom: auto;
    right: 128%;
  }
  &__bottom {
    top: 135%;
    left: 50%;
    margin-left: -60px;
  }
  &__right {
    top: -5px;
    left: 125%;
  }
  &__top {
    bottom: 150%;
    left: 50%;
    margin-left: -50px;
  }
}

.custom-tooltip:hover .tooltiptext,
.tooltiptext {
  visibility: visible;
}
@media screen and (max-width: 600px) {
  .frequency-tooltip {
    left: 0 !important;
    margin-left: -112px !important;
  }
  .mailbox-score-tooltip {
    left: 0 !important;
    margin-left: -32px !important;
  }
  .edit-config-tooltip  {
    left: 0;
    margin-left: -112px !important;

  }
}
