.custom-radio {
  &__input {
    @apply absolute opacity-0;
  }
  &__label {
    @apply flex items-center cursor-pointer;
    &__txt {
      @apply font-medium ml-1;
    }
    &:hover input ~ .custom-radio__circle {
      @apply border-2 border-primary-brand-dark;
    }
    input:checked ~ .custom-radio__circle {
      @apply border-2 border-primary-brand-dark;
      &::after {
        @apply opacity-100 visible;
      }
    }
  }
  &__circle {
    @apply relative inline-flex items-center justify-center flex-shrink-0 border-2 border-secondary;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    transition: all 300ms ease-in;

    &::after {
      content: '';
      @apply absolute bg-primary-brand-dark opacity-0 invisible flex-shrink-0;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      transition: all 300ms ease-in;
    }
  }
}

.radio-btn-date-range {
  &__input {
    @apply absolute opacity-0;
    &:checked ~ .radio-btn-date-range__label {
      @apply text-primary-brand-dark;
      box-shadow: 0px 4px 4px #d6e1ea;
    }
  }
  &__label {
    @apply flex items-center justify-center font-medium bg-white rounded-lg border border-primary-brand-light cursor-pointer;
    padding: 4px 5px;
    transition: all 0.3s;
    font-size: 0.875rem;
    // text-overflow: ellipsis;
    // width: 100px!important;
    @screen lg {
      font-size: 0.9rem;
      padding: 4px 14px;
    }
    &:hover {
      @apply text-primary-brand-dark;
      box-shadow: 0px 4px 4px #d6e1ea;
    }
    &--date {
      @apply text-primary;
      box-shadow: 0px 4px 4px #d6e1ea;
      background-image: url(../../../icons/icon-calendar.svg);
      background-repeat: no-repeat;
      background-position: right 12px center;
      padding-right: 45px;
      width: 175px !important;
    }
  }
}
