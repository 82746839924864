.authentication-module {
  @apply flex items-center h-screen overflow-hidden;
  background: linear-gradient(180deg, #ffffff 0%, #e6eff4 100%);
  &__left-section {
    width: 45%;
    padding: 12% 8% 10%;
    @apply h-full flex flex-col items-center justify-center;
    background: url(../../../images/authentication-section-bg.png) no-repeat center;
    background-size: cover;
    &__content {
      @apply w-full;
    }
  }
  &__right-section {
    width: 55%;
    @apply h-full overflow-y-auto;
    &__content {
      @apply w-full flex flex-col;
      padding: 12% 10%;
      @screen sm {
        padding: 12% 20%;
      }
    }
  }
}

@media screen and (max-width: 1240px) {
  .authentication-module__left-section {
    padding: 8% 5%;
    &__content {
      transform: scale(0.8);
    }
  }
}
/*Tablet Query*/
@media only screen and (max-width: 980px) {
  .authentication-module__left-section {
    display: none;
  }
  .authentication-module__right-section {
    width: 100%;
  }
  .authentication-module__right-section__content {
    width: 100%;
  }
}
