.btn {
  @apply flex items-center justify-center font-medium rounded cursor-pointer;
  font-family: 'Karla', sans-serif;
  padding: 10px 18px;
  transition: all 300ms;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  @media screen and (max-width: 640px) {
    font-size: 0.875rem;
  }
  &--default {
    @apply text-primary border border-primary-brand-light bg-white;
    &:hover {
      background-color: #eef3f9;
      box-shadow: 0px 4px 4px #e4ecf6;
    }
    &:focus {
      background: #ffffff;
      box-shadow: 0px 4px 4px #d6e1ea;
    }
  }
  &--text-red {
    @apply text-red;
  }
  &--text-blue {
    color: #1d5ab5 !important;
  }
  &--primary {
    @apply bg-primary-brand text-white;
    &:hover {
      background: #288ae6;
      box-shadow: 0px 2px 4px rgba(75, 157, 234, 0.47);
    }
    &:focus {
      background: #288ae6;
      box-shadow: 0px 4px 4px #bdd1e3;
    }
    &.btn--cancel-subscription:focus {
      box-shadow: 0px 4px 4px #146fbf;
    }
  }
  &--md {
    padding: 8px 18px;
  }
  &--sm {
    padding: 6px 18px;
  }
  &--xs {
    padding: 4px 18px;
  }
  &.font-bold {
    font-weight: 700 !important;
  }
  &.shadow-true {
    box-shadow: 0px 4px 4px rgba(182, 205, 228, 0.72);
    &:hover {
      box-shadow: none;
    }
  }
  &--red {
    @apply bg-red text-white border-transparent;
    &:hover {
      background-color: #c8433a;
    }
  }
}

.btn-group {
  @apply flex items-center justify-center rounded-lg gap-3;
  padding: 6px;
  background: rgba(147, 180, 210, 0.15);
  &__item {
    padding: 4px 10px;
    @apply text-secondary text-sm font-medium bg-transparent cursor-pointer;
    border-radius: 5px;
    transition: all 300ms ease-in;
    &.active,
    &:hover {
      @apply text-primary-brand-dark font-bold bg-white;
      box-shadow: 0px 9px 10px #c8d4df;
    }
    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
    @screen lg {
      font-size: 1.125rem;
      padding: 8px 10px;
    }
  }
}
