@import 'sass/base/global.scss';
@import 'sass/components/button.scss';
@import 'sass/components/input.scss';
@import 'sass/components/checkbox.scss';
@import 'sass/components/modal.scss';
@import 'sass/components/authentication-module.scss';
@import 'sass/components/loader.scss';
@import 'react-toastify/dist/ReactToastify.min.css';
@import 'sass/components/header.scss';
@import 'sass/components/avatar.scss';
@import 'sass/components/dropdown-menu.scss';
@import 'sass/components/secondary-header.scss';
@import 'sass/components/table.scss';
@import 'sass/components/dot-indicator.scss';
@import 'sass/components/progress-bar.scss';
@import 'sass/components/badge.scss';
@import 'sass/components/switch.scss';
@import 'sass/components/dashboard.scss';
@import 'sass/components/stepper.scss';
@import 'sass/components/profile.scss';
@import 'sass/components/radio.scss';
@import 'sass/components/select.scss';
@import 'sass/components/number-counter.scss';
@import 'sass/components/tooltip.scss';
@import 'sass/components/card.scss';
