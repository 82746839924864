.custom-select {
  @apply relative font-medium w-full border border-border-color rounded truncate bg-white cursor-pointer; 
  padding: 12px;
  padding-right: 45px;
  transition: all 300ms ease-in;
  outline: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: #fff url(../../../icons/icon-arrow-down.svg) no-repeat right 14px center;
  &:focus {
    @apply border border-primary-brand bg-white;
    mix-blend-mode: normal;
  }
  &:hover {
    @apply bg-primary-brand-light border border-primary-brand-light;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &--sm {
    padding: 8px 12px;
    padding-right: 45px;
  }
  &--xs {
    padding: 4px 12px;
    padding-right: 45px;
  }
  &.error {
    @apply border border-green;
  }
}