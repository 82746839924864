.input-field {
  @apply relative w-full bg-white rounded border border-border-color outline-none;
  padding: 8px 12px;
  transition: all 300ms ease-in;
  @media screen and (max-width: 640px) {
    font-size: 0.875rem;
  }
  &-wrapper {
    @apply mb-4;
    &--lg {
      @apply mb-4;
      @screen lg {
        margin-bottom: 2rem;
      }
    }
    .eye-icon {
      @apply cursor-pointer relative;
      left: 92%;
      &-auth {
        @media only screen and (max-width: 525px) {
          left: 89%;
        }
        @media only screen and (min-width: 526px) and (max-width: 768px) {
          left: 93%;
        }
      }
    }
  }
  &-label {
    @apply block font-medium text-sm;
    margin-bottom: 6px;
    @screen lg {
      font-size: 1rem;
    }
  }
  &:hover {
    @apply bg-primary-brand-light border border-primary-brand-light;
  }
  &:focus {
    @apply border border-primary-brand;
  }
  &::placeholder {
    font-size: 0.875rem;
    color: rgba(#485d68, 0.7);
    font-weight: 400;
    @screen sm {
      font-size: 1rem;
    }
  }
  &.text-sm::placeholder {
    font-size: 14px;
    color: rgba(#485d68, 0.7);
    font-weight: 400;
  }
  &.error {
    @apply border border-red;
  }
  &--sm {
    padding: 6px 10px;
  }
  &--xs {
    padding: 4px 10px;
  }
  &--xxs {
    padding: 2px 10px;
  }
  &--search {
    background: #fff url(../../../icons/icon-search.svg) no-repeat;
  }
  &--eye {
    background: #fff url(../../../icons/icon-eye-line.svg) no-repeat;
    cursor: pointer;
  }
  &--eye-off {
    background: #fff url(../../../icons/icon-eye-off-line.svg) no-repeat;
    cursor: pointer;
  }
  &--pos-right {
    background-position: right 12px center;
    padding-right: 40px;
  }
}

.search-icon-close {
  @apply absolute cursor-pointer;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
}

.text-area {
  &:hover {
    @apply border border-primary-brand-light;
    background-color: transparent;
  }
  &:focus {
    @apply border-primary-brand;
    border-width: 2px;
  }
}

.input-check {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  @apply cursor-pointer mr-2 flex items-center justify-center;
  width: 1.15em;
  height: 1.15em;
  transform: translateY(-0.075em);
  outline: 2px solid #056afb;
  outline-style: auto;
  overflow: hidden;
  @media only screen and (max-width: 480px) {
    width: 1.8em;
  }

  // &_checked {
  //   content: '\2714';
  //   color: #056afb !important;
  //   transition: all 0.2s;
  // }
}
.input-check:before {
  -webkit-appearance: none;
  color: transparent !important;
  content: '';
  position: absolute;
  top: -3px;
  bottom: 0;
}
.input-check:checked {
  background-color: #056afb;
}
.input-check:checked::before {
  content: '\2714';
  color: #fff !important;
  transition: all 0.2s;
}
