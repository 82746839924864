.badge {
    padding: 1px 4px;
    @apply text-xs flex items-center justify-center bg-grey-90;
    border-radius: 2px;
    &--success {
        @apply bg-green;
    }
    &--warning {
        @apply bg-yellow;
    }
    &--error {
        @apply bg-red;
    }
}